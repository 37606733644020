import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EducationMark, Exam, TreeNode } from "@/models";
import useSWR from "swr";
import axios from "@/axios";
import {
  Button,
  Col,
  Flex,
  Grid,
  Modal,
  Row,
  Space,
  Spin,
  theme,
  TreeDataNode as BaseTreeDataNode,
  TreeProps,
  Typography,
  Tree as AntdTree,
  message,
} from "antd";
import { CheckOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useQueryState from "@/shared/hooks/use-query-state";
import PageTestView from "@/pages/space/education/[pool_participant_id]/page-test-view.tsx";
import TreeNodeIcon from "@/entities/tree-node/ui/icon";
import styled from "styled-components";
import { usePoolParticipant } from "@/pages/space/education/[pool_participant_id]/context-pool-participant";

const { useBreakpoint } = Grid;

type TreeDataNode = BaseTreeDataNode & {
  meta: {
    treeNode: TreeNode;
    educationMark?: EducationMark;
  };
};

const Tree = styled(AntdTree<TreeDataNode>)`
  .ant-tree-switcher {
    display: none;
  }
`;

const descriptionsGlob = import.meta.glob<React.FC<any>>(
  "@/entities/*/ui/descriptions.tsx",
  {
    eager: true,
    import: "default",
  },
);

const ColContentWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        backgroundColor: token.colorBgContainer,
        padding: token.padding,
        borderRadius: token.borderRadius,
      }}
    >
      {children}
    </div>
  );
};

const Page: React.FC = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const poolParticipant = usePoolParticipant();

  const {
    data: treeNode,
    isLoading,
    error,
  } = useSWR([`/api/tree-nodes/search`, poolParticipant.id], ([url]) =>
    axios
      .post<OrionRestIndexResponse<TreeNode>>(url, {
        filters: [
          {
            field: "is_an_id",
            operator: "=",
            value: poolParticipant.pool!.course!.id,
          },
          {
            field: "is_an_type",
            operator: "=",
            value: "course",
          },
        ],
        includes: [{ relation: "tree.is_an" }],
      })
      .then((res) => res.data.data[0]),
  );

  const {
    data: educationMarks,
    isLoading: isLoadingEducationMarks,
    error: errorEducationMarks,
    mutate: mutateEducationMarks,
  } = useSWR(
    [`/api/education-marks/search`, poolParticipant.id],
    async ([url]) => {
      const educationMarks: EducationMark[] = [];
      let page = 1;
      let lastPage = Number.MAX_SAFE_INTEGER;

      while (page <= lastPage) {
        await axios
          .post<OrionRestIndexResponse<EducationMark>>(
            url,
            {
              filters: [
                {
                  field: "pool_participant_id",
                  operator: "=",
                  value: poolParticipant.id,
                },
              ],
            },
            {
              params: {
                page,
                limit: 50,
              },
            },
          )
          .then((res) => {
            page = res.data.meta.current_page + 1;
            lastPage = res.data.meta.last_page;
            educationMarks.push(...res.data.data);
          })
          .catch((err) => {
            message.error(err.response.data.message ?? "Что-то пошло не так");
            throw err;
          });
      }

      return educationMarks;
    },
  );

  const [selectedTreeNode, setSelectedTreeNode] = useState<TreeNode>();

  type Selected = React.Key | undefined;

  const [selectedNodeKey, setSelectedNodeKey] = useQueryState<Selected>(
    "selected_tree_node",
    {
      valueType: "number",
    },
  );

  const [indexCurrentTree, setIndexCurrentTree] = useState<number>(0);

  const [showEducationTreeModal, setShowEducationTreeModal] =
    useState<boolean>(false);

  const onSelectKey = (key: React.Key) => {
    if (key) {
      setSelectedNodeKey(key);
    }
  };

  const dataMapper = (treeNode: any): TreeDataNode => {
    const EducationMarkValueIcon: React.FC<{ value?: string }> = ({
      value,
    }) => {
      switch (value) {
        case "completed":
          return <CheckOutlined />;
        case "not_completed":
        default:
          return null;
      }
    };

    const educationMark = educationMarks?.find(
      (mark) =>
        mark.material_type === treeNode.is_an_type &&
        mark.material_id === treeNode.is_an_id,
    );

    return {
      key: treeNode.id,
      title: (
        <Space style={{ alignItems: "start" }}>
          <TreeNodeIcon type={treeNode.is_an_type} />
          {treeNode.is_an.name}
          <EducationMarkValueIcon value={educationMark?.value} />
        </Space>
      ),
      children: treeNode.tree.map(dataMapper),
      meta: { treeNode: treeNode, educationMark },
      disabled: educationMark?.is_view_available === false,
    };
  };

  const treeData: TreeProps<TreeDataNode>["treeData"] =
    treeNode?.tree?.map(dataMapper) ?? [];

  const educationMarkCompleted = (markId: EducationMark["id"]) => {
    axios
      .put(`/api/education-marks/${markId}`, { value: "completed" })
      .then(() => mutateEducationMarks());
  };

  const flattenTree = (nodes: TreeDataNode[]) => {
    let flatList = [] as TreeDataNode[];

    nodes.forEach((node) => {
      flatList.push(node);

      if (node.children && node.children.length > 0) {
        flatList = flatList.concat(
          flattenTree(node.children as TreeDataNode[]),
        );
      }
    });

    return flatList;
  };

  const flatTreeData = treeData ? flattenTree(treeData) : [];

  const TreeNodeDescriptions: React.FC = useCallback(() => {
    if (!selectedTreeNode) {
      return (
        <Space direction="vertical">
          <Typography.Text>
            Выберите содержание из структуры курса
          </Typography.Text>
        </Space>
      );
    }

    const TreeNodeDescriptions =
      descriptionsGlob[
        `/src/entities/${selectedTreeNode.is_an_type}/ui/descriptions.tsx`
      ];

    let props: {
      dataSource: any;
      onScormStart?: () => void;
      onTestSuccessFinish?: () => void;
    } = {
      dataSource: selectedTreeNode.is_an,
    };

    const currentTreeNode = flatTreeData?.find(
      (treeNode) =>
        treeNode.meta?.treeNode?.is_an_type === selectedTreeNode.is_an_type &&
        treeNode.meta?.treeNode?.is_an?.id === selectedTreeNode.is_an_id,
    );

    const educationMark = currentTreeNode?.meta?.educationMark;

    if (selectedTreeNode.is_an_type === "scorm") {
      props = {
        ...props,
        onScormStart: () => {
          navigate(
            `/education/${poolParticipant.id}/scorms/${props.dataSource.id}
              ?pool_participant_url=${window.location.pathname}${
              window.location.search
            }${
              educationMark && educationMark.value !== "completed"
                ? `&education_mark_id=${educationMark.id}`
                : ""
            }${
              educationMark
                ? `&education_mark_value=${educationMark.value}`
                : ""
            }`,
          );
        },
      };
    }

    let result = <TreeNodeDescriptions {...props} />;

    if (selectedTreeNode.is_an_type === "test") {
      props = {
        ...props,
        onTestSuccessFinish: () => {
          if (educationMark && educationMark.value !== "completed") {
            mutateEducationMarks();
          }
        },
      };
      result = (
        <PageTestView test={selectedTreeNode.is_an} {...props}>
          {result}
        </PageTestView>
      );
    }

    return result;
  }, [selectedTreeNode]);

  const onNodeSelect: TreeProps<TreeDataNode>["onSelect"] = async (
    selectedKey,
    {
      node: {
        meta: { treeNode: node, educationMark: mark },
      },
    },
  ) => {
    if (node.is_an_type === "lesson") {
      node.is_an = await axios
        .get(`/api/lessons/${node.is_an.id}?include=content_file`)
        .then((res) => res.data.data);
    }
    setSelectedTreeNode(node);
    setShowEducationTreeModal(false);
    onSelectKey(selectedKey[0]);

    const currentTreeIndex = flatTreeData.findIndex(
      (treeDataNode) => treeDataNode.meta.treeNode.id === node.id,
    );

    setIndexCurrentTree(currentTreeIndex);
    if (
      node.is_an_type !== "test" &&
      node.is_an_type !== "scorm" &&
      mark?.value === "not_completed"
    ) {
      educationMarkCompleted(mark.id);
    }
  };

  const moveNode = (direction: "next" | "prev") => {
    if (!selectedNodeKey || !treeData) {
      return;
    }

    const currentIndex = flatTreeData.findIndex(
      (node) => node.key === selectedNodeKey,
    );
    let newIndex = currentIndex;
    if (direction === "next") {
      newIndex =
        currentIndex !== -1 && currentIndex < flatTreeData.length - 1
          ? currentIndex + 1
          : currentIndex;
    } else if (direction === "prev") {
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (!flatTreeData[i].disabled) {
          newIndex = i;
          break;
        }
      }
    } else {
      return;
    }
    if (
      newIndex !== currentIndex &&
      newIndex >= 0 &&
      newIndex < flatTreeData.length
    ) {
      const newNodeKey = flatTreeData[newIndex].key;

      //@ts-ignore
      onNodeSelect([newNodeKey], { node: flatTreeData[newIndex] });
    }
  };

  useEffect(() => {
    if (!treeNode) return;

    if (flatTreeData.length === 1) {
      //@ts-ignore
      onNodeSelect([flatTreeData[0].key], { node: flatTreeData[0] });
      return;
    }

    if (!selectedNodeKey) {
      setSelectedTreeNode(undefined);
      return;
    }

    let currentIndex = flatTreeData.findIndex(
      (node) => node.key === selectedNodeKey,
    );
    let currentKey = selectedNodeKey;

    if (
      !(
        flatTreeData[currentIndex]?.meta.educationMark === undefined ||
        flatTreeData[currentIndex]?.meta.educationMark!.is_view_available
      ) ||
      currentIndex === -1
    ) {
      currentKey = flatTreeData[0].key;
      currentIndex = 0;
    }

    //@ts-ignore
    onNodeSelect([currentKey], { node: flatTreeData[currentIndex] });
  }, [treeNode]);

  const TreeContentEducation: React.FC = useCallback(
    () => (
      <Tree
        blockNode
        showIcon
        defaultExpandAll
        treeData={treeData}
        selectedKeys={[selectedNodeKey]}
        onSelect={onNodeSelect}
      />
    ),
    [treeData, selectedNodeKey],
  );

  if (isLoading || isLoadingEducationMarks) return <Spin />;
  if (error) throw error;
  if (errorEducationMarks) throw errorEducationMarks;
  if (!treeNode || !educationMarks) throw new Error("Data is undefined");

  const disabledNext = !(
    flatTreeData[indexCurrentTree + 1]?.meta.educationMark === undefined ||
    flatTreeData[indexCurrentTree + 1].meta.educationMark!.is_view_available
  );

  return (
    <>
      <Modal
        onCancel={() => setShowEducationTreeModal(false)}
        footer={<></>}
        closeIcon={<></>}
        open={showEducationTreeModal}
      >
        <div style={{ maxHeight: "70vh", overflow: "auto" }}>
          <TreeContentEducation />
        </div>
      </Modal>
      <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
        <AutoBreadcrumb />
        <Row
          gutter={[16, 16]}
          align={"stretch"}
          style={{ height: "calc(100% - 30px)" }}
        >
          {screens.lg && flatTreeData.length > 1 && (
            <Col md={8} lg={8} xl={6} xxl={4} style={{ height: "100%" }}>
              <ColContentWrapper>
                <TreeContentEducation />
              </ColContentWrapper>
            </Col>
          )}
          <Col
            style={{ height: "100%" }}
            {...(flatTreeData.length <= 1
              ? { span: 24 }
              : { xxl: 20, xl: 18, lg: 16, md: 24, xs: 24 })}
          >
            <ColContentWrapper>
              <Flex
                vertical
                gap={8}
                style={{
                  justifyContent: "space-between",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Flex
                  vertical
                  gap={8}
                  style={{ width: "100%", height: "calc(100% - 40px)" }}
                >
                  {!screens.lg && flatTreeData.length > 1 && (
                    <Button onClick={() => setShowEducationTreeModal(true)}>
                      <Typography.Text strong>Содержание</Typography.Text>
                    </Button>
                  )}

                  <TreeNodeDescriptions />
                </Flex>
                {selectedTreeNode && (
                  <Flex
                    justify={indexCurrentTree === 0 ? "end" : "space-between"}
                    style={{ width: "100%" }}
                  >
                    {indexCurrentTree !== 0 && (
                      <Button
                        icon={<LeftOutlined />}
                        onClick={() => moveNode("prev")}
                      >
                        {screens.sm ? "Предыдущий" : null}
                      </Button>
                    )}
                    {indexCurrentTree !== flatTreeData.length - 1 && (
                      <Button
                        disabled={disabledNext}
                        type="primary"
                        onClick={() => moveNode("next")}
                      >
                        {screens.sm ? (
                          <>
                            Следующий <RightOutlined />
                          </>
                        ) : (
                          <RightOutlined />
                        )}
                      </Button>
                    )}
                  </Flex>
                )}
              </Flex>
            </ColContentWrapper>
          </Col>
        </Row>
      </Flex>
    </>
  );
};

export default Page;
